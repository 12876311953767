// Default variable overrides

// scss-docs-start theme-color-variables
$primary:       #0d6efd;
$secondary:     #6c757d;
$success:       #198754;
$info:          #0dcaf0;
$warning:       #ffc107;
$danger:        #dc3545;
$light:         #f8f9fa;
$dark:          #212529;
// scss-docs-end theme-color-variables

// Your variable overrides
$body-bg: #fff;
$body-color: #212529;

$font-family-sans-serif: "Arial", "Roboto";
