

.hero {
    background: url('/images/hero.svg') bottom/cover no-repeat;
    min-height: 400px;
}

.navbar-index {
    background: #faf8ea;
}

.navbar-default {
    background: white;
}



// Apply styles only inside div#content
body.posts.single {
  div#content {
    @extend .container;
    
    // Scientific-style headings with improved hierarchy
    h1, h2, h3 {
      font-weight: $font-weight-bold;
      color: $gray-900;
      margin-bottom: $spacer * 0.75; // Reduced from 1.5
    }
    
    h1 {
      font-size: $h1-font-size * 0.9;
      padding-bottom: $spacer * 0.5; // Reduced padding
      border-bottom: 2px solid $gray-300;
    }
    
    h2 {
      font-size: $h2-font-size * 0.9;
      margin-top: $spacer * 3;
      margin-bottom: $spacer * 0.5; // Reduced from default
    }
    
    h3 {
      font-size: $h3-font-size;
      margin-top: $spacer * 2;
      margin-bottom: $spacer * 0.5; // Reduced from default
    }
    
    p {
      font-size: $font-size-lg;
      line-height: $line-height-base * 1.2;
      color: $gray-800;
      text-align: justify;
      margin-bottom: $spacer * 1.25;
    }
    
    // Enhanced tables for Data Presentation
    table {
      @extend .table;
      @extend .table-bordered;
      @extend .table-hover;
      border-color: $gray-300;
      margin: $spacer * 2 0;
      font-size: $font-size-base;
      
      th {
        font-family: "Arial Narrow", sans-serif;
        background: lighten($primary, 45%);
        font-weight: $font-weight-semibold;
        text-transform: uppercase;
        border-bottom: 2px solid $gray-400;
        padding: $spacer * 0.75;
      }
      
      td {
        padding: $spacer * 0.75;
      }
      
      // Alternating row colors for better readability
      tbody tr:nth-of-type(odd) {
        background-color: rgba($gray-100, 0.5);
      }
      
      // Right-align numerical data
      td.numeric, th.numeric {
        text-align: right;
      }
    }
    
    // Improved bullets for lists
    ul, ol {
      margin-left: $spacer * 1.5;
      margin-bottom: $spacer * 1.5;
      
      li {
        margin-bottom: $spacer * 0.5;
        line-height: $line-height-base * 1.1;
      }
    }
    
    // Section separation
    section {
      margin-bottom: $spacer * 3;
    }
  }
}

@media (max-width: 380px) {
  .navbar .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  
  /* Make the logo smaller on very small screens */
  .navbar-brand img {
    width: 140px;
  }
  
  /* Make the login button smaller */
  .btn-sm {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
  }
  
  /* Make the burger menu more compact */
  .navbar-toggler {
    padding: 0.25rem 0.4rem;
  }
}